import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle: "SpotOn Case Study | Azzouz Hospitality",
    pageDescription:
      "A family-owned hospitality group grows with SpotOn.",
    title: "Funding Family-Owned Growth",
    subTitle: `See how Azzouz Hospitality expanded their D.C. presence with SpotOn Capital.`,
    businessName: "Azzouz Hospitality",
    industry: "Restaurants, casual dining, bars & nightclubs",
    location: "Washington, D.C. ",
    products: [spotonProducts.CAPITAL, spotonProducts.RESTAURANT],
    description:
      "When an opportunity arose to open a new concept at The Wharf, Washington D.C.’s buzzy restaurant destination, the Azzouz brothers knew they had to take it. After the success of their two existing concepts, District Eatz and Urban Roast, they wanted to expand their cocktail offerings and create a unique guest experience for the post-dinner crowd.",
    quoteData: {
      imgName: "azzouz.png",
      quote: `“SpotOn helps your restaurant grow, whether you’re updating your patio or helping your dining room through hard times. SpotOn is a partner that understands and is willing to grow with you and believe in you.”`,
      personName: "Kamal Azzouz",
      personTitle: "Co-owner, Azzouz Hospitality",
    },
    goal: `From the start, Azzouz hospitality had big plans to grow and needed a tech provider that could keep pace. As they planned for their new concept, they needed accessible capital and tech that would help them reach profitability faster.`,
    solution: `At Urban Roast, the Azzouz brothers honed their experiential dining approach, streamlining operations with a handheld point-of-sale system. Easily accessible reports from the point-of-sale help Azzouz Hospitality cater to their guests and monitor voids and discounts. With SpotOn Capital, Azzouz Hospitality can fund their new concept while still supporting their existing restaurants. Read their story.`,
    solutionReadMore: {
        text: `Read their story.`,
        url: `https://www.spoton.com/blog/gather-around-at-urban-roast/`,
      },
    results: {
      title: `The results`,
      stats: [
        {
          title: `25%`,
          description: `Increase in daily sales YOY`,
        },
        {
          title: `<1 minute`,
          description: `Time to contact customer support`,
        },
        {
          title: `<48 hours`,
          description: `To access funding`,
        },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "Creating better work experiences with SpotOn Teamwork",
        imageName: "the-bungalow-kitchen.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-08-18",
        href: "/case-studies/the-bungalow-kitchen",
        goals: [
            resourceGoals.STREAMLINE_OPERATIONS,
            resourceGoals.GET_SMARTER_DATA,
            resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
        ],
    },
    {
        title: "Fewer spreadsheets. More teamwork",
        imageName: "guisto-newport.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-12-02",
        href: "/case-studies/guisto-newport",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
        ],
      },
    {
        title: "Managing 3 popular Japanese restaurants",
        imageName: "momoya-with-logo.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-07-22",
        href: "/case-studies/momoya",
        goals: [
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
          resourceGoals.CONNECT_AND_SELL_ONLINE,
        ],
      },
  ];
  